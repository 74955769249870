import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emoji',
  standalone: true
})
export class EmojiPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }
    return this.convertEmoticonsToEmoji(value);
  }

  private convertEmoticonsToEmoji(text: string): string { 
    const emoticonToEmojiMap: { [key: string]: string } = {
        ':)': '😊',
        ':-)': '😊',
        ':(': '😞',
        ':-(': '😞',
        ':D': '😄',
        ':-D': '😄',
        ':P': '😋',
        ':-P': '😋',
        'XD': '😂',
        'xD': '😂',
        ':O': '😮',
        ':-O': '😮',
        ':*': '😘',
        ':-*': '😘',
        ';)': '😉',
        ';-)': '😉',
        'x)': '😆',
        'x-)': '😆',
        ':|': '😐',
        ':-|': '😐',
        ':S': '😖',
        ':-S': '😖',
        ':/': '😕',
        ':-/': '😕',
        ':\\': '😕',
        ':-\\': '😕',
        ':@': '😠',
        ':-@': '😠',
        ':$': '😳',
        ':-$': '😳',
        ':^)': '😏',
        '<3': '❤️',
        '</3': '💔',
        ':#': '🤐',
        ':-#': '🤐',
        'B)': '😎',
        'B-)': '😎',
        '8)': '😎',
        '8-)': '😎',
        'O:)': '😇',
        'O:-)': '😇',
        '>:)': '😈',
        '>:-)': '😈',
        ':/)': '😅',
        ':-/)': '😅',
        ':3': '😺',
        ':-3': '😺',
        ':]': '😌',
        ':-]': '😌',
        ':>': '😏',
        ':-<': '😒',
        ':->': '😏',
        ':<': '😖',
        ':-<<': '😖',
        '=)': '😊',
        '=-)': '😊',
        '=D': '😄',
        '=-D': '😄',
        '=P': '😋',
        '=-P': '😋',
        ':,(': '😢',
        ':-,(': '😢',
        ':’(': '😢',
        ':-’(': '😢'
    };

    return text.replace(/[:;]-?[)(DP*Ox]/g, match => emoticonToEmojiMap[match] || match);
  }
}


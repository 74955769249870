 
import { Injectable } from '@angular/core';
import { DataSnapshot, Database, endBefore, equalTo, get, limitToLast, onValue, orderByChild, orderByKey, query, ref, remove, set, update } from '@angular/fire/database';
import { BehaviorSubject, Observable, firstValueFrom, from  } from 'rxjs'; 

interface Message {
  id: string;
  senderId: string;
  senderName: string;
  text: string;
  timestamp: string;
  chatId: string;
  read?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class ChatService {
 
  myMessages$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);  
  prev10Messages$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  chatId: string | null = null; 

  newMessages$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);  
  hideToasts$: BehaviorSubject<boolean>  = new BehaviorSubject<boolean>(false);  

  oldestMessageKey: string | null = null;

  constructor( private database: Database ) { }

  async getMessages(userIds: string[]) { 
    this.chatId = null; 
    try { 
      const chatSnapshot = await firstValueFrom(this.getChatBetweenUsers(userIds));
      const chat  = chatSnapshot; 
      if (chat) {  // SE LA CHAT ESISTE GIA' mi metto in ascolto dei messaggi
        this.chatId = chat.id;
        if(this.chatId) {
          this.retireveChatMessages();
        } 
      } else {  // SE LA CHAT NON ESISTE LA CREO e mi metto in ascolto dei messaggi
        this.chatId = null;
        try {
          const newChatId = await this.createChat(userIds);
          if (newChatId) {
            this.chatId = newChatId;
            this.retireveChatMessages();
          } else {
            console.error('Failed to create chat');
          }
        } catch (error) {
          console.error('Error while creating chat:', error);
        } 
      }
    } catch (error) {
      console.error('Error in getMessages', error);
    } 
  }

  async createChat(userIds: string[]): Promise<string | null> {

    let chatId: string | null = `chat-${Date.now()}`; 
    const path = `/crm-on-chats/chats/${chatId}`;
    const node = ref(this.database, `${path}`); 

    const newChat = {
      id: `${chatId}`,
      createdAt: new Date().toISOString(),
      timestamp: `${Date.now()}`,
      messages: [],
      participants: [...userIds] 
    };  

    try {
      await set(node, newChat);       
      return chatId;
    } catch (error) {
      console.error('>>>>> creating chat: error', error);
      return null;
    } 
  }

  getMessagesByChatID(chatId: string) {
    this.chatId = chatId;
    this.retireveChatMessages();
  }


  sendmessage(  message: string, myId: string, myName: string ) { 

      if(!this.chatId) return;

      const messageId= `mess-${Date.now()}`; 
      const path= `/crm-on-chats/chats/${this.chatId}/messages/${messageId}`  ;    
  
      const node = ref(this.database, `${path}`); 

      const newMess: Message = {
        id: `${messageId}`,
        text: `${message}`,
        senderName: `${myName}`,
        timestamp: new Date().toISOString(),
        senderId: `${myId}`,
        chatId: `${this.chatId}`,
        read: false
      };  

      set(node, newMess).then(() => {
        // console.log('>>>>> sendmessage: success', message); 
      }).catch((error) => {
        console.error('>>>>> asendmessage: error', error);
      });   
  }

  deleteMessage(messageId: string) {
    if(!this.chatId) return;
    const path= `/crm-on-chats/chats/${this.chatId}/messages/${messageId}`  ;    
    const node = ref(this.database, `${path}`); 
     
    remove(node).then(() => {
        //console.log('>>>>> deleted message: success'); 
    }).catch((error) => {
        console.error('>>>>> delete message: error', error);
    });
  }

  listenForNewMessages(meId: string) {  

    this.getMyChats(meId).subscribe(chats => { 

      chats.forEach((chat: any) => {
        const messagesRef = ref(this.database, `/crm-on-chats/chats/${chat.id}/messages`);
        //  const lastMessageQuery = query(messagesRef, limitToLast(1));
        const unreadMessagesQuery = query(messagesRef, orderByChild('read'), equalTo(false));

        
        onValue(unreadMessagesQuery, (snapshot: DataSnapshot) => {
          const lastMessage = snapshot.val(); 
          if(!lastMessage) return;

          const lastMessages: Message[]  = [];
          
          Object.entries(lastMessage).map(( [id , message]) => { 
            const messageJson = {...(message as object) } as Message;
            if (messageJson.senderId !== meId) {
              lastMessages.push(messageJson);
            } 
          });
    
          this.newMessages$.next(lastMessages);  
        });
      });
    });
  }

  private  retireveChatMessages() { 
      const path= `/crm-on-chats/chats/${this.chatId}/messages`  ;   
      const collection  = query(ref(this.database, `${path}`),  orderByKey(),  limitToLast(3) ); 

      onValue(collection, (snapshot: DataSnapshot) => {      
        const jsonRes =  snapshot.val();  
        if(!jsonRes) return;

        this.newMessages$.next([]);  // svuoto l'array di messaggi non letti

        const dataArr : Message[] = [];

        Object.entries(jsonRes).map( async ([id , message]) => { 
          const messageJson = {...(message as object) } as Message;
          await this.markMessageAsRead(this.chatId!, messageJson.id);
          
          dataArr.push(messageJson);
        });  

        this.myMessages$.next(dataArr);

        setTimeout(() => {
          this.oldestMessageKey = dataArr[0].id; // Memorizza la chiave del messaggio più vecchio
        }, 2000);

      }, (errorObject: any) => {
        console.log('>>>> retrieve fb messages ' , errorObject.name);
      }); 
  } 

  retirevePrevTenMessages() { 
    if (!this.oldestMessageKey) {
      console.log('No previous messages to load');
      return;
    }

    const path= `/crm-on-chats/chats/${this.chatId}/messages`  ;   
    const collection  = query(ref(this.database, `${path}`),  orderByKey(), endBefore(this.oldestMessageKey), limitToLast(10) ); 

    onValue(collection, (snapshot: DataSnapshot) => {      
      const jsonRes =  snapshot.val();  
      if(!jsonRes) return; 

      const dataArr : Message[] = [...this.prev10Messages$.value];

      Object.entries(jsonRes).map( async ([id , message]) => { 
        const messageJson = {...(message as object) } as Message; 
        dataArr.unshift(messageJson);  
      });  

      setTimeout(() => { 
        dataArr.sort((a: any, b:any) =>  +a.id.replace('mess-', '') - +b.id.replace('mess-', '')  );   
        this.oldestMessageKey = dataArr[0].id; // Memorizza la chiave del messaggio più vecchio
      }, 1000);  

      this.prev10Messages$.next(dataArr); 

    }, (errorObject: any) => {
      console.log('>>>> retrieve prev 10 messages ' , errorObject.name);
    }); 
}

  private getMyChats(userId:  string): Observable<any> {
    const path= '/crm-on-chats/chats';
    const chatsRef = ref(this.database, path);
    return from(
      get(query(chatsRef)).then((snapshot: DataSnapshot) => {
        const chats = snapshot.val();
        if (!chats) return null;
        const chatsArr = [];
        for (const [key, value] of Object.entries(chats)) {
          const participants = (value as any).participants;
          if (participants && participants.includes(userId)) {
            chatsArr.push( { id: key, ...(value as object) } );
          } 
        }
        return chatsArr;
      })
    );
  }


  private getChatBetweenUsers(userIds: string[]): Observable<any> {
    const path= '/crm-on-chats/chats';
    const chatsRef = ref(this.database, path);
    return from(
      get(query(chatsRef)).then(snapshot => {
        const chats = snapshot.val();
        if (!chats) return null;
        for (const [key, value] of Object.entries(chats)) {
          const participants = (value as any).participants;
          if ( participants && (participants.sort().join(',') === userIds.sort().join(','))) {
            return { id: key, ...(value as object) };
          }
        }
        return null;
      })
    );
  }

  private getMessagesForChat(chatId: string): Observable<any> {
    const path= `/crm-on-chats/chats/${chatId}/messages`; 
    const messagesRef = ref(this.database, path);
    return from(get(messagesRef));
  }

  
  async markMessageAsRead(chatId: string, messageId: string) {
    const messageRef = ref(this.database, `/crm-on-chats/chats/${chatId}/messages/${messageId}`);
    await update(messageRef, { read: true });
  }
  

   
}

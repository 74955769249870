import { Injectable, signal } from '@angular/core';
import { DataSnapshot, Database, onValue, query, ref } from '@angular/fire/database';  
 

// interface Notification {
//   message: string; 
// }

// interface User {
//   notifications?: { [key: string]: Notification }; 
// }


@Injectable({
  providedIn: 'root'
})
export class CrmUsersService { 

  crmUsers = signal<any[]>([]); 
  totUsers = signal<number>(0);

  // myNotificationsSignal = signal<any[] | [] >([]); 

  constructor(   private database: Database,  ) { }

  getFirebaseCrmUsers(q: string, key: string){    
    const collection  = query(ref(this.database, 'crm-on-users')  );
  
    onValue(collection, (snapshot: DataSnapshot) => {      
      const jsonRes =  snapshot.val();   // trasformo snapshot in un JSON
    
      const dataArr = Object.entries(jsonRes).map(([uid, user]) => { 
        // let notifications: Notification[] = [];
        let u = user as any;
        // const typedUser = user as User; 
        // if (typedUser && typedUser.notifications) {
        //   notifications = Object.values(typedUser.notifications).map(value => value); 
        // }      
        // return { uid, user, notifications };
        return {...u, id: uid ,   mobile:  u.mobile?.number , mobileVerified: u.mobile?.verified   };
      });

      this.crmUsers.set([...dataArr]); 
      this.totUsers.set(dataArr.length);

      // console.log('>>> ALL CRM USERS ' ,  this.crmUsers());

    }, (errorObject: any) => {
      console.error('>>>> retrieve fb crm users ' , errorObject.name);
    }); 
  }
 

}
